$.lui("part", ".part_ui_alert", function(selector){
    selector.on("click", `[data-click="close"]`, function(){
        let elm = $(this);
        elm.closest(selector).fadeOut(300);
        setTimeout(function() {
            elm.closest(selector).remove();
        },300);
    });
    setTimeout(function () {
        selector.fadeOut(300);
        setTimeout(function() {
            selector.remove();
        },300);
    },8000);
});