$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_header_logo").clone(),
            nav = header.find(".elm_header_nav").clone(),
            cta = header.find(".col--cta").find(".part_ui_btn").clone();

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);
        layout_nav.find(".wrp_nav_content").append(cta);

        layout_nav.find(".col--sub").each(function () {
            const link = $(this).children(".elm_header_link");
            const sub = $(this).children(".elm_nav_sub");

            link.off("click");

            link.on("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                sub.stop().slideToggle();
            })
        })
    } else {
        let layout_nav = $("#layout_nav");

        layout_nav.find(".col--sub").each(function () {
            const link = $(this).children(".elm_header_link");
            const sub = $(this).children(".elm_nav_sub");

            link.off("click");

            link.on("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                sub.stop().slideToggle();
            })
        })
    }

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });
});
