$.lui("comp",".comp_visual_carousel",function (selector) {
    let slider = selector.find("[data-lib-flickity]");
    if(slider.length){
        $.lib_flickity(function(){
            slider.flickity({
                groupCells: false,
                cellAlign: "left",
                setGallerySize: false,
                autoPlay: 3000,
                pageDots: true,
                wrapAround: true,
                contain: true,
                prevNextButtons: false,
                watchCSS: false,
                on: {
                    ready: function () {
                        slider.addClass("flickity--flex");
                        slider.find(".flickity-page-dots").appendTo(selector.find("[data-lib-flickity-dots]"));
                    }
                }
            });
        })
    }
});