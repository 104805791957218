$.lui("comp",".comp_gallery",function (selector) {
    bodyLoaded(function(){
        if (selector.find(".lib--lightgallery-item").length) {
            $.importScript(cdnjs.lightgallery, function(){
                let gallery = selector.find("[data-lib-lightgallery]").lightGallery({
                    thumbnail: true,
                    selector: ".lib--lightgallery-item",
                    exThumbImage: 'data-exthumbimage',
                    fullScreen: false,
                    zoom: true,
                    actualSize: false,
                    hash: false,
                    download: true,
                    autoplay: false,
                    autoplayControls: false,
                });
                gallery.on('onAfterOpen.lg',function(event){
                    $('#lg-download').attr('data-no-swup', '');
                });
            });
        }
    });
});