$.lui("comp", ".comp_map", function(selector){

    let map_element = selector.find("[data-google-map]");

    if(map_element.length) {
        $.importScript(cdnjs.googlemap.replace("APIKEY", map_element.data("google-map")), function(){
            let zoom = map_element.data("zoom"),
                coords = map_element.data('coords').split(/[\s,]+/),
                marker = map_element.data("marker");

            let map = new google.maps.Map(map_element[0], {
                zoom: zoom,
                center: {
                    lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                },
                mapTypeControl: false,
            });

            let image = {
                url: marker,
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 60),
                size: new google.maps.Size(48, 60),
            };

            let map_marker = new google.maps.Marker({
                position: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                map: map,
                icon: image
            });

            google.maps.event.addListener(map_marker, 'click', function(){
                window.open(map_element.data("marker-url"), '_blank');
            });
        });
    }
});