$.lui("comp",".comp_visual",function (selector) {
    selector.on("click","[data-scroll]",function () {
        let next = selector.closest("section").next("section, div");
        if(next.length) {
            next.anchor_anim(500);
        }
    });

    if(selector.next(".comp_products_detail").length) {
        selector.addClass("type--products");
    }
});