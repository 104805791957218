$.lui("comp",".comp_partners",function (selector) {
    let slider = selector.find("[data-lib-flickity]");
    if(slider.length){
        let slides = slider.children();
        $.lib_flickity(function(){
            slider.flickity({
                groupCells: false,
                cellAlign: "left",
                setGallerySize: true,
                autoPlay: false,
                pageDots: true,
                wrapAround: true,
                contain: true,
                prevNextButtons: true,
                watchCSS: false,
                on: {
                    ready: function () {
                        slider.addClass("flickity--flex");

                        if(slides.length < 5){
                            slider.addClass("flickity--disabled-d");
                        }
                        if(slides.length < 4){
                            slider.addClass("flickity--disabled-t");
                        }
                        if(slides.length < 3){
                            slider.addClass("flickity--disabled-m");
                        }
                    }
                }
            });
        })
    }
});