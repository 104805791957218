$.lui("comp",".comp_products_detail",function (selector) {
    selector.off("click","[data-show-variations]");
    selector.on("click","[data-show-variations]",function () {
        selector.find("[data-lib-tabs-item=\"nav\"]").eq(1).trigger("click");
    });

    selector.on("click", `[data-click="variation"]`, function(){
        selector.find(`[data-click="variation"]`).removeClass("state--selected");
        $(this).addClass("state--selected").find(`input[type="radio"]`).prop("checked", true);

        let dialogUrl = $('[data-add-to-basket]').attr('data-lib-dialog');
        let productId = $(this).find(`input[type="radio"]`).val();
        let url = setGetParameter(dialogUrl, 'idProduct', productId);

        $('[data-add-to-basket]').data('lib-dialog', url);
    });

    selector.on("change", `[data-change="variation"]`, function(){
        selector.find(`[data-click="variation"]`).removeClass("state--selected");

        let dialogUrl = $('[data-add-to-basket]').attr('data-lib-dialog');
        let productId = $(this).val();
        let url = setGetParameter(dialogUrl, 'idProduct', productId);

        $('[data-add-to-basket]').data('lib-dialog', url);
    });
});

function setGetParameter(url, paramName, paramValue)
{
    if (url.indexOf(paramName + "=") >= 0)
    {
        var prefix = url.substring(0, url.indexOf(paramName + "="));
        var suffix = url.substring(url.indexOf(paramName + "="));
        suffix = suffix.substring(suffix.indexOf("=") + 1);
        suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
        url = prefix + paramName + "=" + paramValue + suffix;
    }
    else
    {
        if (url.indexOf("?") < 0)
            url += "?" + paramName + "=" + paramValue;
        else
            url += "&" + paramName + "=" + paramValue;
    }

    return url;
}