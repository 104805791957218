$.lui("part", ".part_item_faq", function(selector) {
    selector.on("click",".wrp_item_head",function () {
        let el = $(this).closest(selector),
            body = el.find(".wrp_item_body");

        if(el.hasClass("state--active")) {
            el.removeClass("state--open");
            body.stop().slideUp(function(){
                el.removeClass("state--active");
            })
        }
        else {
            el.addClass("state--open");
            body.stop().slideDown(function(){
                el.addClass("state--active");
            })
        }
    });
});