$.lui("comp",".comp_banner_video",function (selector) {
    let dialog = "<div class=\"part_dialog part_dialog_basic part_dialog_video size--big\">\n" +
        "    <form class=\"wrp_dialog\">\n" +
        "        <button class=\"elm_dialog_close icon--close\" data-lib-dialog-close></button>\n" +
        "        <div class=\"wrp_dialog_body\" data-ratio=\"16/9\">\n" +
        "            <iframe width=\"720\" height=\"405\" src=\"https://www.youtube.com/embed/VIDEO_ID\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>\n" +
        "        </div>\n" +
        "    </form>\n" +
        "</div>";

    selector.on("click","[data-video]",function (e) {
        if(win.width() > 960) {
            e.preventDefault();
            fn_lib_dialog().open(dialog.toString().replace("VIDEO_ID",$(this).data("video")));
        }
    })
});