$.lui("comp", ".comp_package_basket", function(selector) {

    selector.on("change", "[data-toggle] input", function () {
        let row = $(this).closest("[data-toggle]").next(".elm_form_row");
        if ($(this).prop("checked") === true) {
            row.slideDown(300);
        } else {
            row.slideUp(300);
        }
    });

    selector.on('change', 'input[type="number"][data-ajax-link]', function () {
        let ajaxLink = $(this).attr('data-ajax-link');
        let count = $(this).val();

        $.ajax({
            url: ajaxLink,
            data: {count: count},
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    selector.on('click', '[data-add-voucher]', function () {

        let url = $(this).attr('data-ajax-link');
        let code = $('input[data-voucher-code]').val();

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            cache: false,
            data: {
                code: code
            }
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });

    });

    selector.on('change', '[data-service-item]', function () {
        let ajaxLink = $(this).attr('data-ajax-link');

        $.ajax({
            url: ajaxLink,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    if ($('.packeta-selector-branch-id').length > 0) {
        let packetaName;
        let packetaId = selector.find('[name="packeta"]').val();
        let ajaxLink = $('[name="packeta"]').data('ajax-link');

        console.log(ajaxLink);

        setInterval(function () {
            let newValue = selector.find('[name="packeta"]').val();
            if (packetaId !== newValue) {
                packetaId = newValue;
                packetaName = selector.find('[name="packetaBranchName"]').val();

                $.ajax({
                    url: ajaxLink,
                    method: 'POST',
                    data: {'id': packetaId, 'name': packetaName}
                });
            }
        }, 500);
    }
});