let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.0/dist/flickity.pkgd.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "lightgallery": "https://cdn.jsdelivr.net/npm/lightgallery@1.6.12/dist/js/lightgallery-all.min.js"
};
